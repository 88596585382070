import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<div class="root">
			<div class="root__logo">
				<i class="fas fa-brain" />
			</div>
			<h1 class="root__title">
				<div class="subtitle is-4">
					Welcome to
				</div>
				<div class="title is-1">
					Cortex
				</div>
			</h1>
		</div>
	</React.StrictMode>,
	document.getElementById('root'),
);
